import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import PropTypes from "prop-types";

import bgLeft from "@common/website/assets/images/nybyggbergen/bg_left.jpg";
import bgRight from "@common/website/assets/images/nybyggbergen/bg_right.jpg";
import top from "@common/website/assets/images/nybyggbergen/top.jpg";
import mail from "@common/website/assets/images/mail.jpg";

const OuterContainer = styled.div`
	position: relative;
	padding: 0 0 100px 0;
	width: 100%;
	height: 100%;
	float: left;
`;

const Container = styled.div`
	padding: 150px 18px 0 18px;
	max-width: 1040px;
	margin: 0 auto;
	align-self: center;
`;

const ContentContainer = styled.div`
	width: 100%;
	max-width: 600px;
	margin: 0 auto;
`;

const Heading = styled.h2`
	color: ${props => props.theme.colors.gold.primary};
	text-align: ${props => (props.center ? "center" : "left")};
`;

const Text = styled.p``;

const BgLeft = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: url(${bgLeft});
	background-repeat: no-repeat;
	background-size: 25%;
	background-position: left bottom;
	pointer-events: none;
	z-index: -1;
`;

const BgRight = styled.div`
	position: absolute;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: url(${bgRight});
	background-repeat: no-repeat;
	background-size: 25%;
	background-position: right bottom;
	pointer-events: none;
	z-index: -1;
`;

const Image = styled.img`
	width: 100%;
	margin-bottom: 50px;
`;

const FooterCorrections = createGlobalStyle`
    footer {
        margin-top: 0 !important;
    }
`;

const ProjectStepWrapper = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	margin-top: 50px;
	margin-bottom: 50px;
	z-index: 2;

	@media screen and (max-width: 998px) {
		display: none;
	}
`;

const StyledStep = styled.div`
	width: 100%;
	position: relative;
	display: inline-flex;
	flex-flow: column;
	flex: 1 1 100%;
	align-items: center;

	&:first-child {
		.step-number {
			&::after {
				display: none;
			}
		}
	}

	&:last-child {
		.step-number {
			&::before {
				display: none;
			}
		}
	}
`;

const StyledStepNumber = styled.div`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 35px;
	height: 35px;
	border: solid 2px ${props => props.theme.colors.gold.primary};
	font-family: "Domaine Display", serif;
	font-size: 18px;
	color: white;
	background: black;

	&::before {
		content: "";
		position: absolute;
		left: 50%;
		width: 50%;
		height: 2px;
		background: ${props => props.theme.colors.gold.primary};
		z-index: -1;
	}

	&::after {
		content: "";
		position: absolute;
		right: 50%;
		margin: auto;
		width: 50%;
		height: 2px;
		background: ${props => props.theme.colors.gold.primary};
		z-index: -1;
	}
`;

const StyledStepLabel = styled.div`
	margin-bottom: 10px;
	max-width: 150px;
	height: 38px;
	font-family: "Domaine Display", serif;
	text-align: center;
	text-transform: uppercase;
	font-size: 11px;
`;

const ProjectStep = ({ label, number }) => (
	<StyledStep>
		<StyledStepLabel className="step-label">{label}</StyledStepLabel>
		<StyledStepNumber className="step-number">{number}</StyledStepNumber>
	</StyledStep>
);

const AgentWrapper = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	width: 100%;
	margin-top: 50px;
`;

const StyledAgentWrapper = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	max-width: 210px;
	margin-right: 40px;

	@media screen and (max-width: 500px) {
		margin-right: 0;
		margin-bottom: 20px;
		width: 100%;
	}

	&:last-child {
		margin-right: 0;
	}
`;

const StyledAgentImage = styled.div`
	width: 150px;
	height: 150px;
	border-radius: 100%;
	background: url(${props => props.src}) no-repeat white;
	background-size: cover;
	background-position: center;
`;

const StyledAgentName = styled.a`
	min-height: 40px;
	margin: 15px;
	text-align: center;
	font-size: 1.3em;
	line-height: 20px;
	color: ${props => props.theme.colors.gold.primary};
	text-decoration: none;
`;

const StyledAgentTitle = styled.p`
	margin-top: 0;
	margin-bottom: -5px;
	font-size: 0.8em;
`;

const StyledAgentOffice = styled.p`
	margin: 0;
	font-size: 0.8em;
`;

const StyledAgentContactRow = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 20px;
	width: 100%;
`;

const StyledAgentMobile = styled.a`
	color: ${props => props.theme.colors.gold.primary};
	text-decoration: none;
`;

const StyledAgentEmail = styled.div`
	margin-left: 20px;
	width: 25px;
	height: 25px;
	background: url(${mail});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	&:hover {
		cursor: pointer;
	}
`;

const Agent = ({ image, link, name, title, office, phone, emailOnClick }) => (
	<StyledAgentWrapper>
		<StyledAgentImage src={image} />
		<StyledAgentName href={link}>{name}</StyledAgentName>
		<StyledAgentTitle>{title}</StyledAgentTitle>
		<StyledAgentOffice>{office}</StyledAgentOffice>
		<StyledAgentContactRow>
			<StyledAgentMobile href={`tel:${phone ? phone.replace(" ", "") : ""}`}>{phone}</StyledAgentMobile>
			{emailOnClick ? <StyledAgentEmail onClick={emailOnClick} /> : null}
		</StyledAgentContactRow>
	</StyledAgentWrapper>
);

const NybyggBergen = ({ history }) => {
	const steps = [
		{
			number: 1,
			label: "Rådgivning nye prosjekter"
		},
		{
			number: 2,
			label: "Akkvisisjon"
		},
		{
			number: 3,
			label: "Regulering"
		},
		{
			number: 4,
			label: "Politisk-kontakt"
		},
		{
			number: 5,
			label: "Prosjekt-utvikling"
		},
		{
			number: 6,
			label: "Markeds-føring"
		},
		{
			key: "7-step",
			number: 7,
			label: "Salgsstart"
		},
		{
			number: 8,
			label: "Oppfølging"
		},
		{
			number: 9,
			label: "Kontrakt-signering"
		},
		{
			number: 10,
			label: "Overtakelse"
		}
	];

	return (
		<OuterContainer>
			<Container>
				<Heading center>Fra tomtekjøp til ferdig prosjekt!</Heading>
				<ProjectStepWrapper>
					{steps.map((item, index) => (
						<ProjectStep key={`step-${index}`} number={item.number} label={item.label} />
					))}
				</ProjectStepWrapper>
				<Image src={top} />
				<ContentContainer>
					<Heading>Gi oss et skikkelig prosjekt!</Heading>
					<Text>
						PrivatMegleren Nyeboliger Bergen er det meglerhuset som besitter mest kompetanse innen nye boligprosjekter,
						og er kanskje hovedgrunnen til at vi har det største nettverket av grunneiere og utbyggere. Vi er en
						rådgiver og kompetansepartner før prosjekter blir til prosjekter. Vårt konsept består av ti punkter hvor
						kunden selv velger hvilke tjenester som er aktuelle for sitt prosjekt.
					</Text>
					<Text>
						Det som gjør PrivatMegleren Nyeboliger Bergen til en solid samarbeidspartner, i tillegg til nettverket av
						grunneiere og utbyggere, er at vi også håndterer disipliner som akkvisisjon, regulering og politisk kontakt.
					</Text>
					<Text>
						Vi er en totalleverandør og har kompetanse på alle deler av verdikjeden - fra uregulert tomt til
						nøkkelferdige boliger. Ulike utbyggere har ulike behov, alt ettersom hvor i prosessen man er, og vi er
						trygge på at våre tjenester vil innfri deres ønsker og behov.
					</Text>
					<AgentWrapper>
						<Agent
							link="https://privatmegleren.no/megler/Ximena_Andrea_Sagenes"
							image="https://cdn.reeltime.no/pm_assets/img/meglerbilder/bergensentrum/ximena.jpg"
							name="Ximena Andrea Sagenes"
							title="Eiendomsmegler MNEF / Partner"
							office="PrivatMegleren Vikebø & Jørgensen"
							phone="93 68 05 86"
							emailOnClick={() =>
								(window.location.href = "https://privatmegleren.no/megler/Ximena_Andrea_Sagenes/kontakt")
							}
						/>
						<Agent
							link="https://privatmegleren.no/megler/Yngve_Fl%C3%B8isand"
							image="https://cdn.reeltime.no/pm_assets/img/meglerbilder/nyeboligerbergen/yngve_fløisand.jpg"
							name="Yngve Fløisand"
							title="Daglig Leder"
							office="PrivatMegleren Nyeboliger Bergen"
							phone="90 07 05 00"
							emailOnClick={() =>
								(window.location.href = "https://privatmegleren.no/megler/Yngve_Fl%C3%B8isand/kontakt")
							}
						/>
					</AgentWrapper>
				</ContentContainer>
			</Container>
			<BgLeft />
			<BgRight />
			<FooterCorrections />
		</OuterContainer>
	);
};

ProjectStep.propTypes = {
	label: PropTypes.string,
	number: PropTypes.string
};

Agent.propTypes = {
	image: PropTypes.string,
	name: PropTypes.string,
	link: PropTypes.string,
	title: PropTypes.title,
	office: PropTypes.string,
	phone: PropTypes.string,
	emailOnClick: PropTypes.func
};

NybyggBergen.propTypes = {
	history: PropTypes.object
};

export default NybyggBergen;
